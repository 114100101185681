import * as Luxon from 'luxon'

type dateOrDateString = string | Date

const getDateWithFormatOptions = (dateIncoming?: dateOrDateString, options: Intl.DateTimeFormatOptions = { year: '2-digit', month: 'numeric', day: 'numeric' }) => {
  if (!dateIncoming) return ''
  const date = (typeof dateIncoming === 'string')
    ? new Date(dateIncoming)
    : dateIncoming
  return date.toLocaleDateString(undefined, options)
}

export const getDateTinyFormat = (dateIncoming?: dateOrDateString) => {
  const options = { year: '2-digit', month: 'numeric', day: 'numeric' } as Intl.DateTimeFormatOptions
  return getDateWithFormatOptions(dateIncoming, options)
}

export const getDateFullFormat = (dateIncoming?: dateOrDateString) => {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' } as Intl.DateTimeFormatOptions
  return getDateWithFormatOptions(dateIncoming, options)
}

export const getWrittenDate = (dateIncoming?: dateOrDateString) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric', weekday: 'long' } as Intl.DateTimeFormatOptions
  return getDateWithFormatOptions(dateIncoming, options)
}

export const getDateRelativeFormat = (dateIncoming?: dateOrDateString) => {
  if (!dateIncoming) return ''
  const date = (typeof dateIncoming === 'string')
    ? new Date(dateIncoming)
    : dateIncoming
  const relativeTime = Luxon.DateTime.fromJSDate(date).toRelative()
  return relativeTime
  // const options = { year: '2-digit', month: 'numeric', day: 'numeric' } as Intl.DateTimeFormatOptions
  // return date.toLocaleDateString(undefined, options)
}

const TweetDisplayDateFormats: { [key: string]: (date?: dateOrDateString) => (string | null)} = {
  'relative': getDateRelativeFormat,
  'full': getDateFullFormat,
  'numericSmall': getDateTinyFormat,
  'written': getWrittenDate,
}

export const getFormattedDate = (date?: dateOrDateString, format = 'relative') => {
  const formatFunction = TweetDisplayDateFormats?.[format]
  if (!format || !date) {
    console.error('Date Format Function Error')
    return ''
  }
  return formatFunction(date)
}
