import styles from './BlogPostMetrics.module.css'
// import Trend from 'react-trend'

type Props = {

}

/* This example requires Tailwind CSS v2.0+ */
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'
import { ChartBarIcon, CursorClickIcon, FireIcon }
from '@heroicons/react/outline'
// MailOpenIcon, UsersIcon
// import LoadingContent from 'components/LoadingContent'
import { usePagesForDomain } from 'data/pages/client'

const stats = [
  {
    id: 1,
    name: 'Total Engagement',
    stat: '5,143',
    icon: FireIcon,
    change: '122',
    changeType: 'increase',
    trend: [0,2,5,9,5,10,3,5,0,0,1,8,6,9,8],
  },
  {
    id: 2,
    name: 'Post Consistency',
    stat: '93.6%',
    icon: ChartBarIcon,
    change: '6.4%',
    changeType: 'increase',
    trend: [3,1,1,3,0,3,2,1,0,2,1,2,1,1,2,2],
  },
  {
    id: 3,
    name: 'Post Engagement',
    stat: '3473',
    icon: CursorClickIcon,
    change: '3.2%',
    changeType: 'decrease',
    trend: [9,3,1,7,2,5,9,10,3,5,0,1,8,2,0],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const BlogPostMetrics = ({  }: Props) => {
  const { pages, isPagesError, isPagesLoading } = usePagesForDomain()
  return (
    <div>
      <h3 className={styles.metricsTitle}>Blog and Twitter Activity <span className={styles.dateRange}>Last 30 days</span></h3>

      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
          >
            <div className={styles.sparkLine}>
              {/* <Trend
                smooth
                autoDraw
                autoDrawDuration={3000}
                autoDrawEasing="ease-out"
                data={item.trend}
                gradient={['#f00', 'violet']}
                radius={10}
                strokeWidth={4}
                strokeLinecap={'round'}
              /> */}
            </div>
            <dt>
              <div className="absolute bg-gray-700 rounded-md p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">{item.name}</p>
            </dt>
            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
              <p
                className={classNames(
                  item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                  'ml-2 flex items-baseline text-sm font-semibold'
                )}
              >
                {item.changeType === 'increase' ? (
                  <ArrowSmUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                ) : (
                  <ArrowSmDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
                )}

                <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                {item.change}
              </p>
              <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">
                  <a href="#" className="font-medium text-gray-600 hover:text-gray-500">
                    {' '}
                    View all &raquo;<span className="sr-only"> {item.name} stats</span>
                  </a>
                </div>
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}

export default BlogPostMetrics
