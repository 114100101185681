import { getLabelForBlogPostStatus, getStatusStylesForBlogPost } from 'data/pages/mapping'
import { BlogPostStatus } from 'data/pages/types'
import styles from './StatusPillForBlogPost.module.css'

type Props = {
  status: BlogPostStatus,
}

const StatusPillForBlogPost = ({ status }: Props) => {
  const statusStyle = getStatusStylesForBlogPost(status)
  // console.log({ status, statusStyle })
  return <div className={`${styles.statusPillForBlogPost} ${statusStyle}`}>
    {getLabelForBlogPostStatus(status)}
  </div>
}

export default StatusPillForBlogPost
