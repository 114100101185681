import { getStatusIconForComment } from 'data/comments/icons'
import { CommentStatus } from 'data/comments/types'

const commentStatusStyles = {
  approved: 'bg-green-100 text-green-800',
  waiting: 'bg-yellow-100 text-yellow-800',
  rejected: 'bg-gray-100 text-gray-800',
}

export const getStatusStylesForComment = (status: CommentStatus) => commentStatusStyles[status]

export const labelForCommentStatus = {
  approved: 'Approved',
  waiting: 'Awaiting Approval',
  rejected: 'Rejected',
}

export const getLabelForCommentStatus = (status: CommentStatus) => labelForCommentStatus?.[status]

const CommentStatusDetails = {
  approved: {
    label: getLabelForCommentStatus('approved'),
    styles: commentStatusStyles['approved'],
    description: 'Hi description',
    icon: getStatusIconForComment('approved'),
  },
  waiting: {
    label: getLabelForCommentStatus('waiting'),
    styles: commentStatusStyles['waiting'],
    description: 'Hi description',
    icon: getStatusIconForComment('waiting'),
  },
  rejected: {
    label: getLabelForCommentStatus('rejected'),
    styles: commentStatusStyles['rejected'],
    description: 'Hi description',
    icon: getStatusIconForComment('rejected'),
  },
  // needsSharing: 'bg-gray-100 text-gray-800',
}

export const getStatusDetailsForComment = (status: CommentStatus) => CommentStatusDetails[status]
