import NextLink from 'next/link'
import styles from './Link.module.css'

type Props = {
  children: string,
  className?: string,
  href: string,
  isExternal?: boolean,
}

const Link = ({ children, className = '', href, isExternal = false }: Props) =>
<NextLink href={href}><a className={`${styles.link} ${className}`} target={ isExternal ? '_new' : undefined}>{ children }</a></NextLink>

export default Link
