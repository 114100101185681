import HtmlSnippet from './TargetSnippets/HtmlSnippet'
// import styles from './InstallationSnippet.module.css'
import ComingSoonSnippet from 'components/InstallationSnippet/TargetSnippets/ComingSoonSnippet'
import { Tab } from 'components/Tabs'
import NextjsSnippet from 'components/InstallationSnippet/TargetSnippets/NextjsSnippet'
import WordPressSnippet from 'components/InstallationSnippet/TargetSnippets/WordPressSnippet'

type Props = {
  target: string,
  tab: Tab,
}

const getSnippetForTarget = (target: string, tab: Tab) => {
  const snippet = snippetForTarget?.[target]
  if (snippet) {
    return snippet
  }
  return <ComingSoonSnippet tab={tab} />
}

const snippetForTarget: { [key: string]: React.ReactNode } = {
  html: <HtmlSnippet />,
  nextjs: <NextjsSnippet />,
  wordpress: <WordPressSnippet />,
}

const InstallationSnippet = ({ target, tab }: Props) => <>
  <h4 className="sr-only">{ tab.name }</h4>
  { getSnippetForTarget(target, tab) }
</>

export default InstallationSnippet
