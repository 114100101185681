import Button from 'components/Button'
import { Tab } from 'components/Tabs'
import { usePlausible } from 'next-plausible'
import { useState } from 'react'

type Props = {
  tab: Tab,
}

const ComingSoonSnippet = ({ tab }: Props) => {
  const plausible = usePlausible()
  const [hasVoted, setHasVoted] = useState(false)
  const handleVote = () => {
    plausible('Installation Snippet Requested', {
      props: {
        value: tab.value,
      }
    })
    setHasVoted(true)
  }
  return <div className="mt-3 sm:mt-0 sm:ml-4">
    <p><strong>Blotter for { tab.name } is coming soon!</strong></p>
    <p>{
      hasVoted
        ? <span>Thanks for voting!</span>
        : <span className='flex'><Button className='mr-1' variant='small' onClick={handleVote}>Vote for { tab.name }</Button><span>to let us know you want it.</span></span>
    }
    </p>

    <p>For now, you should be able to manually install the HTML snippet.</p>
  </div>
}

export default ComingSoonSnippet
