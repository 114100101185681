import Clickable from 'components/Clickable'
import { isFunction } from 'lodash'
import styles from './Tabs.module.css'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export type Tab = {
  value: string,
  name: string,
  icon: React.ComponentType,
  isSvg?: boolean,
  isLabelSuppressed?: boolean,
  secondaryLabel?: string,
}

type Props = {
  tabs: Tab[],
  value?: string,
  onChange?: (newValue: string) => void,
}

const Tabs = ({ tabs, value, onChange }: Props) => {
  const currentTab = tabs.find((tab) => tab.value === value)
  const getTabChangeHandler = (tabValue: string) => () => { isFunction(onChange) && onChange(tabValue) }
  return <div className={styles.tabs}>
    <div className="sm:hidden">
      <label htmlFor="tabs" className="sr-only">
        Select a tab
      </label>
      {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
      <select
        id="tabs"
        name="tabs"
        className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
        value={currentTab?.value}
        onChange={(event) => {
          console.log('MOBILE CHANGE', event.currentTarget.value)
          getTabChangeHandler(event.currentTarget.value)()
        }}
      >
        {tabs.map((tab) => (
          <option key={tab.value} value={tab.value}>{tab.name}</option>
        ))}
      </select>
    </div>
    <div className="hidden sm:block">
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => {
            const isCurrent = tab.value === value
            return <Clickable
              key={tab.value}
              onClick={getTabChangeHandler(tab.value)}
              // href={tab.href}
              className={classNames(
                isCurrent
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm relative gap-1'
              )}
              aria-current={isCurrent ? 'page' : undefined}
            >
              { !!tab.secondaryLabel && <div className='absolute left-0 right-0 -bottom-0 flex justify-center'>
                <div className='text-[9px] whitespace-nowrap text-gray-500 px-2 rounded-full w-20'>{ tab.secondaryLabel }</div>
              </div> }
              <tab.icon
                className={classNames(
                  tab.isLabelSuppressed
                    ? 'h-5 w-16 mx-1'
                    : 'h-5 w-5 -ml-0.5 mr-2',
                  isCurrent
                    ? tab.isSvg
                      ? 'fill-indigo-500'
                      : 'text-indigo-500'
                    : tab.isSvg
                      ? 'fill-gray-400 group-hover:fill-gray-500'
                      : 'text-gray-400 group-hover:text-gray-500',
                )}
                aria-hidden="true"
              />
              { !tab.isLabelSuppressed && <span>{tab.name}</span> }
            </Clickable>
          })}
        </nav>
      </div>
    </div>
  </div>
}

export default Tabs
