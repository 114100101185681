import { Icon } from 'components/Icons/Types'

 const BubbleIcon = ({ width = 64, height = 64, className = undefined }: Icon) =>
  <svg width={ width } height={ height } viewBox='0 0 1780 408' className={ className }>
    <g>
      {/* <defs>
        <rect id="SVGID_1_" width="1780" height="408"/>
      </defs>
      <clipPath id="SVGID_2_">
        <use xlinkHref="#SVGID_1_"  overflow="visible"/>
      </clipPath> */}
      <path d="M867.521,108.618c-35.948,0-71.379,15.433-98.428,45.775V4.765h-53.53v251.908
        c0,0.006,0,0.012,0,0.019c0,81.778,66.294,148.072,148.073,148.072c81.778,0,148.072-66.294,148.072-148.072
        C1011.708,174.914,949.299,108.618,867.521,108.618 M863.636,347.732c-50.281,0-91.042-40.761-91.042-91.041
        c0-50.281,40.761-91.042,91.042-91.042c50.281,0,91.042,40.761,91.042,91.042C954.678,306.972,913.917,347.732,863.636,347.732"/>
      <path d="M1199.169,108.618c-35.948,0-71.379,15.433-98.429,45.775V4.765h-53.528
        v251.908c0,0.006-0.001,0.012-0.001,0.019c0,81.778,66.294,148.072,148.073,148.072c81.777,0,148.072-66.294,148.072-148.072
        C1343.356,174.914,1280.946,108.618,1199.169,108.618 M1195.284,347.732c-50.281,0-91.042-40.761-91.042-91.041
        c0-50.281,40.761-91.042,91.042-91.042c50.28,0,91.041,40.761,91.041,91.042C1286.325,306.972,1245.564,347.732,1195.284,347.732"
        />
      <path d="M414.364,282.628V116.65h57.414v165.457c0,42.278,24.532,69.419,65.766,69.419
        c41.755,0,65.766-26.098,65.766-69.419V116.65h57.935v165.978c0,76.726-46.453,122.136-123.701,122.136
        C462.382,404.764,414.364,357.789,414.364,282.628"/>
      <rect x="1389.544" y="4.765" width="57.414" height="393.213"/>
      <path d="M1771.963,279.498h-219.74c8.352,43.843,39.146,73.594,93.429,73.594
        c31.839,0,70.462-12.005,95.517-30.272l24.01,42.276c-28.708,21.401-74.116,39.146-121.615,39.146
        c-105.433,0-150.843-73.594-150.843-147.71c0-42.278,13.049-77.249,39.669-104.913c26.619-27.663,61.591-41.755,103.867-41.755
        c40.189,0,73.594,12.527,99.17,38.101c25.576,25.576,38.623,60.024,38.623,103.867
        C1774.049,259.664,1773.527,269.058,1771.963,279.498 M1552.223,232.521h163.891c-4.696-44.365-37.578-73.071-79.857-73.071
        C1592.934,159.45,1560.574,188.678,1552.223,232.521"/>
      <path d="M232.07,108.618c-35.948,0-71.379,15.433-98.429,45.775V4.765H80.112v251.908
        c0,0.006,0,0.012,0,0.019c0,81.778,66.294,148.072,148.073,148.072c81.778,0,148.073-66.294,148.073-148.072
        C376.258,174.914,313.848,108.618,232.07,108.618 M228.185,347.732c-50.281,0-91.042-40.761-91.042-91.041
        c0-50.281,40.761-91.042,91.042-91.042c50.28,0,91.041,40.761,91.041,91.042C319.226,306.972,278.465,347.732,228.185,347.732"/>
      <path fill="#0000FF" d="M41.196,331.531c-20.223,0-36.616,16.394-36.616,36.616
        s16.393,36.616,36.616,36.616s36.616-16.394,36.616-36.616S61.419,331.531,41.196,331.531"/>
    </g>
  </svg>


export default BubbleIcon
