import { PageView, Tweet, TweetUrl, Conversation, Blog } from '@prisma/client'
import { ContentItem } from 'components/ListOfContent/ListOfContent'
import { PublicMetrics } from 'data/comments/types'
import { ConversationForClient } from 'data/conversations/types'
import { getStatusIconForBlogPost } from 'data/pages/icons'
// import { ConversationTweets, ConversationWithUnpartitionedTweets } from 'data/conversations/types'
import { Page, PageWithBlogAndConversations, PageToSave, BlogPostStatus } from 'data/pages/types'

export const getPageWithDefaults = (page: Partial<PageToSave>) => ({
  pageViewCount: 0,
  isPageViewConfirmed: false,
  shareCount: 0,
  isShared: false,
  status: 'not_shared' as BlogPostStatus,
  replyCount: 0,
  replies: [],
  ...page,
})

const getDateForTweet = (tweet: Tweet) => {
  if (tweet.external_created_at) {
    const date = new Date(tweet.external_created_at)
    return date
  }
  const internalDate = new Date(tweet.createdAt)
  return internalDate
}

export const getContentItemFromPage = (page: Page): ContentItem => ({
  ...page,
  title: page.title || page.url,
  href: `/app/host/${page.hostId}/blogposts/${page.id}`,
  likeCount: 0,
  date: page.createdAt,
  datetime: '2022-03-08',
})

export const getContentItemRepliesAcrossPages = (pages: Page[]): ContentItem[] => {
  const replies = pages.reduce((previous, current) => {
    if (current.replies) {
      return [...previous, ...current.replies]
    }
    return previous
  }, <Tweet[]>[])
  const repliesAcrossPages = replies.map(reply => {
    const publicMetrics = reply.public_metrics as PublicMetrics
    return {
      id: reply.id,
      title: reply.text,
      href: `https://twitter.com/${reply.author_username}/status/${reply.external_id}`,
      shareCount: publicMetrics?.retweet_count || 0,
      pageViewCount: 0,
      likeCount: publicMetrics?.like_count || 0,
      replyCount: publicMetrics?.reply_count || 0,
      // status: BlogPostStatus | CommentStatus,
      date: getDateForTweet(reply),
      datetime: reply.external_created_at || undefined,
    }
  })
  return repliesAcrossPages
}

export const getStatusForPage = (tweetCount: number, conversationCount: number): BlogPostStatus => {
  if (tweetCount > 0) {
    return BlogPostStatus.shared
  }
  if (conversationCount === 0) {
    return BlogPostStatus.no_conversation
  }
  return BlogPostStatus.not_shared
}

export const getPageFromBlogPost = async (blogPost: PageWithBlogAndConversations, pageViews: PageView[], shares: TweetUrl[], conversations: ConversationForClient[]) => {
  const url = `${blogPost.blog.urlDomain}${blogPost.urlPath}`
  const commonlySharedTitleText = blogPost.blog.sharedBlogPostTitleText
  const visibleConversations = conversations.filter(conversation => conversation.isEnabled)
  return {
    url,
    id: blogPost.id,
    title: blogPost.title?.replace(commonlySharedTitleText, ''),
    hostId: blogPost.blog.id,
    pageViewCount: pageViews.length,
    isPageViewConfirmed: pageViews.length > 0,
    shareCount: shares.length,
    isShared: shares.length > 0,
    createdAt: blogPost.blog.createdAt,
    status: getStatusForPage(shares.length, visibleConversations.length), // TODO: why is this here? Should only be FE?
    conversations,
  }
}

export type BlogPostWithBlogUrlAndPath = {
  blog: Blog | {
    urlDomain?: string,
  },
  urlPath?: string,
}

export const getUrlForBlogPost = (blogPost: BlogPostWithBlogUrlAndPath) => {
  const blogDomain = blogPost.blog.urlDomain
  const blogPostPath = blogPost.urlPath
  return `${blogDomain}${blogPostPath}`
}

export const getUrlForBlog = (blog: Blog) => `${blog.urlDomain}${blog.urlBasePath}`

export const LabelForBlogPostStatus = {
  shared: 'Tweeted',
  not_shared: 'URL Not Tweeted',
  no_conversation: 'Needs Conversation',
}

export const getLabelForBlogPostStatus = (status: BlogPostStatus) => LabelForBlogPostStatus?.[status]

const blogPostStatusStyles = {
  // shared: 'bg-green-100 text-green-400',
  // not_shared: 'bg-yellow-100 text-yellow-800',
  // no_conversation: 'bg-red-100 text-red-800',
  shared: 'text-green-400 border-green-400 bg-green-50 hover:text-green-500',
  not_shared: 'text-amber-500 border-amber-400 bg-amber-50 hover:text-amber-500',
  no_conversation: 'text-rose-400 border-rose-400 bg-rose-50 hover:text-rose-500',
}

export const getStatusStylesForBlogPost = (status: BlogPostStatus) => blogPostStatusStyles[status]

const blogPostStatusDetails = {
  shared: {
    label: getLabelForBlogPostStatus('shared'),
    labelDetailed: 'Your blog post has been shared, great work',
    textStyles: 'text-green-400 font-bold text-lg',
    styles: blogPostStatusStyles['shared'],
    icon: getStatusIconForBlogPost('shared'),
  },
  not_shared: {
    label: getLabelForBlogPostStatus('not_shared'),
    labelDetailed: 'Your blog post hasn’t been shared',
    textStyles: 'text-yellow-500 font-bold text-lg',
    styles: blogPostStatusStyles['not_shared'],
    icon: getStatusIconForBlogPost('not_shared'),
  },
  no_conversation: {
    label: getLabelForBlogPostStatus('no_conversation'),
    labelDetailed: 'Your blog post has no conversations',
    textStyles: 'text-red-500 font-bold text-lg',
    styles: blogPostStatusStyles['no_conversation'],
    icon: getStatusIconForBlogPost('no_conversation'),
  },
  // needsSharing: 'bg-gray-100 text-gray-800',
}

export const getStatusDetailsForBlogPost = (status: BlogPostStatus) => blogPostStatusDetails[status]
