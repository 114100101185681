import { Blog, BlogPost, Conversation, Tweet, TweetUrl } from '@prisma/client'
import { ConversationForClient } from 'data/conversations/types'

export const BlogPostStatus: { [key: string]: BlogPostStatus } = {
  shared: 'shared',
  not_shared: 'not_shared',
  no_conversation: 'no_conversation',
}

export type BlogPostStatus = 'shared' | 'not_shared' | 'no_conversation'

export type Page = {
  url: string,
  id: string,
  hostId: string,
  pageViewCount: number,
  isPageViewConfirmed: boolean,
  shareCount: number,
  isShared: boolean,
  createdAt?: Date,
  status: BlogPostStatus,
  replyCount?: number,
  replies?: Tweet[],
  conversations?: ConversationForClient[],
  title?: string,
  description?: string,
}

export type PageToSave = Omit<Page, 'id' | 'replies' | 'conversations'>

export type TweetUrlWithTweets = TweetUrl & {
  tweets: Tweet[],
}

export type PageWithConversations = BlogPost & {
  conversations: Conversation[],
}

export type PageWithBlogAndConversations = BlogPost & {
  blog: Blog,
  conversations: Conversation[],
}

// export type PageWithBlogAndConversationsForClient = BlogPost & {
//   blog: Blog,
//   conversations: ConversationForClient[],
// }

export type PageWithBlog = BlogPost & {
  blog: Blog,
}

// This is visible to public
export type BlogSettings = {
  slug: string
  commentsEnabled: boolean
  conversationlessCommentingEnabled: boolean
}
