import { DuplicateIcon } from '@heroicons/react/outline'
import Link from 'components/Link'
import LoadingContent from 'components/LoadingContent'
import NumberIcon from 'components/NumberIcon'
import CircleSpinner from 'components/Spinner/CircleSpinner'
import { useBlogWithSlug, useCurrentBlogId } from 'data/blogs/client'

const NextjsSnippet = () => {
  const blogSlug = useCurrentBlogId()
  // const { blogs, isBlogsLoading } = useBlogs()
  const { blog, isBlogLoading } = useBlogWithSlug(blogSlug, { pollForRefresh: true })
  if (isBlogLoading) {
    return <LoadingContent />
  }
  if (!blog) {
    return <div>No blog found.</div>
  }
  const hasPageViews = !!blog?.pageViews?.length
  // const { blogs, isBlogsLoading } = useBlogs()
  if (isBlogLoading) {
    return <LoadingContent />
  }
  if (!blog) {
    return <div>No blog found.</div>
  }
  const codeSnippets = [{
    id: 1,
    text: '// YourBlogPostComponent.jsx',
    isComment: true,
  }, {
    id: 2,
    text: 'import Script from \'next/script\'',
    isComment: false,
  }, {
    id: 3,
    text: '// ... [your blog post code]',
    isComment: true,
  }, {
    id: 4,
    text: '<Script src=\'https://useblotter.com/static/js/blotterComments.js\' strategy=\'afterInteractive\' />',
    isComment: false,
  }, {
    id: 5,
    text: `<div id='blotter' data-hostid='${blog.slug}' />`,
    isComment: false,
  }]
  return <>
    <div className='mb-4'><NumberIcon>1</NumberIcon> Place the JSX below <strong>wherever you want your blog comments to appear</strong>. Typically this is at the end of the main content in your blog post page.</div>
    <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
      <div className="mt-3 sm:mt-0 sm:ml-1">
        {/* <code className="text-sm font-medium text-gray-600">&lt;!-- Place this HTML after the end of your blog post content --&gt;</code> */}
        { codeSnippets.map(snippet =>
          <code key={snippet.id} className={`block text-sm break-all ${ snippet.isComment ? 'text-gray-400' : 'text-gray-900'}`}>
            { snippet.text }
          </code>
        )}
      </div>
      <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          onClick={() => { navigator.clipboard.writeText(codeSnippets.map(snippet => snippet.text).join('\r\n'))}}
        >
          <DuplicateIcon width={16} height={16} className='mr-1' /> Copy
        </button>
      </div>
    </div>
    <div className='mt-6 mb-4'>
      { hasPageViews
        ? <><NumberIcon>2</NumberIcon> <strong>You’re done! Blotter Comments will now show on your site</strong>
          <p className='px-7 pt-7'>Next, learn how to make the most of your new Blotter setup and <Link href='/docs/starting-conversations' isExternal>jumpstart your conversations &raquo;</Link></p>
        </>
        : <div className='bg-yellow-400/30 py-4 px-6 rounded'>
          <div className='flex items-center'>
            <CircleSpinner color='#de3165' /> <strong>Waiting for Installation Confirmation</strong>
          </div>
          <div className='mt-6 mb-4'>
            <p>Once you’ve set up Blotter Commenting on your site, visit one of the pages you installed it on.</p>
            <p>Waiting for your site to deploy? Take a minute to read about <Link href='/docs/starting-conversations' isExternal>how Blotter helps you start conversations</Link>.</p>
          </div>
        </div>
      }
    </div>
    {/* <div className='mt-6 mb-4'>
      <strong>Step 2:</strong> <strong>Visit the pages you added Blotter to.</strong>
      <p>This will automatically search for Tweets that include the page URL and load them as comments, along with any replies.</p>
      <p>Soon you’ll be able to connect any Tweet and its replies as comments.</p>
    </div> */}
  </>
}

export default NextjsSnippet
