import { CheckCircleIcon, ClockIcon, StopIcon } from '@heroicons/react/outline'
import { CommentStatus } from 'data/comments/types'

const width = 16
const height = 16

const IconForCommentByStatus = {
  approved: <CheckCircleIcon width={width} height={height} color='green' />,
  waiting: <ClockIcon width={width} height={height} color='yellow' />,
  rejected: <StopIcon width={width} height={height} color='red' />,
}

export const getStatusIconForComment = (status: CommentStatus) => IconForCommentByStatus[status]
