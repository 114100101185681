// import { Blog } from '@prisma/client'
import { DuplicateIcon } from '@heroicons/react/outline'
import Link from 'components/Link'
import LoadingContent from 'components/LoadingContent'
import NumberIcon from 'components/NumberIcon'
import CircleSpinner from 'components/Spinner/CircleSpinner'
import { useBlogWithSlug, useCurrentBlogId } from 'data/blogs/client'
// import styles from './TargetSnippets.module.css'

// type Props = {}

const WordPressSnippet = () => {
  const blogSlug = useCurrentBlogId()
  // const { blogs, isBlogsLoading } = useBlogs()
  const { blog, isBlogLoading } = useBlogWithSlug(blogSlug, { pollForRefresh: true })
  if (isBlogLoading) {
    return <LoadingContent />
  }
  if (!blog) {
    return <div>No blog found.</div>
  }
  const hasPageViews = !!blog?.pageViews?.length
  // const blotterScriptText = '<script src=\'https://useblotter.com/static/js/blotterComments.js\' defer></script>'
  // const blotterDivText = `<div id='blotter' data-hostid='${blog.slug}' />`
  return <>
    <div className='mb-4'><NumberIcon>1</NumberIcon> Download the <Link href='/static/downloads/blotter_wordpress_plugin.zip'>Blotter WordPress Plugin here</Link>. The plugin is not yet available in the WordPress plugin directory, but will be soon.</div>
    <div className='mt-6 mb-4'><NumberIcon>2</NumberIcon> Open your WordPress Admin Dashboard and navigate to Plugins &gt; Add New.</div>
    <div className='mt-6 mb-4'><NumberIcon>3</NumberIcon> Click the "Upload Plugin" button, then select the zip file you downloaded and click "Install Now".</div>
    <div className='mt-6 mb-4'><NumberIcon>4</NumberIcon> Activate the plugin by clicking the "Activate" button.</div>
    <div className='mt-6 mb-4'><NumberIcon>5</NumberIcon> Navigate to Comments &gt; Blotter and insert your Blotter Host ID: <span className='text-emerald-500 font-bold'>{ blogSlug }</span></div>
    <div className='mt-6 mb-4'>
      { hasPageViews
        ? <><NumberIcon>6</NumberIcon> <strong>You’re done! Blotter Comments will now show on your site</strong>
          <p className='px-7 pt-7'>Next, learn how to make the most of your new Blotter setup and <Link href='/docs/starting-conversations' isExternal>jumpstart your conversations &raquo;</Link></p>
        </>
        : <div className='bg-yellow-400/30 py-4 px-6 rounded'>
          <div className='flex items-center'>
            <CircleSpinner color='#de3165' /> <strong>Waiting for Installation Confirmation</strong>
          </div>
          <div className='mt-6 mb-4'>
            <p>Once you’ve set up Blotter Commenting on your site, visit one of the pages you installed it on.</p>
            <p>Waiting for your site to deploy? Take a minute to read about <Link href='/docs/starting-conversations' isExternal>how Blotter helps you start conversations</Link>.</p>
             {/* in the same browser you’re using now */}
          </div>
        </div>
      }
    </div>
  </>
}

export default WordPressSnippet
