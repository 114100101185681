import { getLabelForCommentStatus, getStatusStylesForComment } from 'data/comments/mapping'
import { CommentStatus } from 'data/comments/types'
import styles from './StatusPillForComment.module.css'

type Props = {
  status: CommentStatus,
}

const StatusPillForComment = ({ status }: Props) => {
  const statusStyle = getStatusStylesForComment(status)
  return <div className={`${styles.statusPillForComment} ${statusStyle}`}>
    {getLabelForCommentStatus(status)}
  </div>
}

export default StatusPillForComment
