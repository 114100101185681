import { CheckCircleIcon, ArrowCircleUpIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import { BlogPostStatus } from 'data/pages/types'
import colors from 'tailwindcss/colors'

const width = 48
const height = 48

const IconForBlogPostByStatus = {
  shared: <CheckCircleIcon width={width} height={height} color={colors.green[400]} />,
  not_shared: <ArrowCircleUpIcon width={width} height={height} color={colors.yellow[400]} />,
  no_conversation: <ExclamationCircleIcon width={width} height={height} color={colors.red[400]} />,
}

export const getStatusIconForBlogPost = (status: BlogPostStatus) => IconForBlogPostByStatus[status]
