// import { Blog } from '@prisma/client'
import { DuplicateIcon } from '@heroicons/react/outline'
import Link from 'components/Link'
import LoadingContent from 'components/LoadingContent'
import NumberIcon from 'components/NumberIcon'
import CircleSpinner from 'components/Spinner/CircleSpinner'
import { useBlogWithSlug, useCurrentBlogId } from 'data/blogs/client'
// import styles from './TargetSnippets.module.css'

// type Props = {}

const HtmlSnippet = () => {
  const blogSlug = useCurrentBlogId()
  // const { blogs, isBlogsLoading } = useBlogs()
  const { blog, isBlogLoading } = useBlogWithSlug(blogSlug, { pollForRefresh: true })
  if (isBlogLoading) {
    return <LoadingContent />
  }
  if (!blog) {
    return <div>No blog found.</div>
  }
  const hasPageViews = !!blog?.pageViews?.length
  const blotterScriptText = '<script src=\'https://useblotter.com/static/js/blotterComments.js\' defer></script>'
  const blotterDivText = `<div id='blotter' data-hostid='${blog.slug}' />`
  return <>
    <div className='mb-4'><NumberIcon>1</NumberIcon> Place the HTML below <strong>at the end of the &lt;head&gt; tag of your blog.</strong> Because of the `defer` attribute, it will not slow down the rendering of your page.</div>
    <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
      <div className="mt-3 sm:mt-0 sm:ml-1">
        {/* <code className="text-sm font-medium text-gray-600">&lt;!-- Place this HTML after the end of your blog post content --&gt;</code> */}
        <code className="block text-sm text-gray-900 break-all">
          { blotterScriptText }
        </code>
      </div>
      <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          onClick={() => { navigator.clipboard.writeText(blotterScriptText)}}
        >
          <DuplicateIcon width={16} height={16} className='mr-1' /> Copy
        </button>
      </div>
    </div>
    <div className='mt-6 mb-4'><NumberIcon>2</NumberIcon> Place the HTML below <strong>in your blog post page template, after the content of your blog post</strong>. This will determine where your Blotter Comment section will appear.
    {/* <ul className='list-disc list-inside'>
      <li>When a user has finished reading your blog post, and</li>
      <li>Where Blotter Comments will appear, if enabled</li>
    </ul> */}
    </div>
    <div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-center sm:justify-between">
      <div className="mt-3 sm:mt-0 sm:ml-1">
        {/* <code className="text-sm font-medium text-gray-600">&lt;!-- Place this HTML after the end of your blog post content --&gt;</code> */}
        <code className="block text-sm text-gray-900 break-words">
          { blotterDivText }
        </code>
      </div>
      <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          onClick={() => { navigator.clipboard.writeText(blotterDivText)}}
        >
          <DuplicateIcon width={16} height={16} className='mr-1' /> Copy
        </button>
      </div>
    </div>
    <div className='mt-6 mb-4'>
      { hasPageViews
        ? <><NumberIcon>3</NumberIcon> <strong>You’re done! Blotter Comments will now show on your site</strong>
          <p className='px-7 pt-7'>Next, learn how to make the most of your new Blotter setup and <Link href='/docs/starting-conversations' isExternal>jumpstart your conversations &raquo;</Link></p>
        </>
        : <div className='bg-yellow-400/30 py-4 px-6 rounded'>
          <div className='flex items-center'>
            <CircleSpinner color='#de3165' /> <strong>Waiting for Installation Confirmation</strong>
          </div>
          <div className='mt-6 mb-4'>
            <p>Once you’ve set up Blotter Commenting on your site, visit one of the pages you installed it on.</p>
            <p>Waiting for your site to deploy? Take a minute to read about <Link href='/docs/starting-conversations' isExternal>how Blotter helps you start conversations</Link>.</p>
             {/* in the same browser you’re using now */}
          </div>
        </div>
      }
    </div>
  </>
}

export default HtmlSnippet
