import { CodeIcon } from '@heroicons/react/outline'
import BubbleIcon from 'components/Icons/BubbleIcon'
import GatsbyIcon from 'components/Icons/GatsbyIcon'
// import JekyllIcon from 'components/Icons/JekyllIcon'
import NextJsIcon from 'components/Icons/NextJsIcon'
// import WixIcon from 'components/Icons/WixIcon'
import WordPressIcon from 'components/Icons/WordPressIcon'
import InstallationSnippet from 'components/InstallationSnippet'
// import LoadingContent from 'components/LoadingContent'
import Tabs, { Tab } from 'components/Tabs'
// import { useBlogs } from 'data/blogs/client'
// import { first, isEmpty } from 'lodash'
import { usePlausible } from 'next-plausible'
import { useState } from 'react'
import styles from './InstallationSnippetWidget.module.css'

const tabs: Tab[] = [
  { name: 'HTML', value: 'html', icon: CodeIcon },
  { name: 'WordPress', value: 'wordpress', icon: WordPressIcon, isSvg: true },
  { name: 'Bubble', value: 'bubble', icon: BubbleIcon, isSvg: true, isLabelSuppressed: true },
  { name: 'Next.js', value: 'nextjs', icon: NextJsIcon, isSvg: true },
  { name: 'Gatsby', value: 'gatsby', icon: GatsbyIcon, isSvg: true },
  // { name: 'Wix', value: 'wix', icon: WixIcon, isSvg: true },
  // { name: 'Jekyll', value: 'jekyll', icon: JekyllIcon, isSvg: true },
]

const InstallationSnippetWidget = () => {
  const plausible = usePlausible()
  const [currentValue, setCurrentValue] = useState<string>(tabs[0].value)
  const getCurrentTab = (value: string) => tabs.find(tab => tab.value === value)
  const handleTabChange = (newValue: string) => {
    plausible('Installation Snippet Viewed', {
      props: {
        value: newValue,
      }
    })
    setCurrentValue(newValue)
  }
  return <div className={styles.installationSnippetWidget}>
    <div className={styles.installationSnippet}>
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">NEXT STEP: Install Code Snippet on Your Blog</h3>
          <div className="mt-5">
            <Tabs tabs={tabs} onChange={handleTabChange} value={currentValue} />
          </div>
          <div className="mt-5">
            <InstallationSnippet target={currentValue} tab={getCurrentTab(currentValue)} />
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default InstallationSnippetWidget
