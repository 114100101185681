import { Blog } from '@prisma/client'
import { ChatAlt2Icon, ChatIcon, ChevronRightIcon, DocumentTextIcon, EyeIcon, ShareIcon } from '@heroicons/react/outline' // ThumbUpIcon
import LoadingContent from 'components/LoadingContent'
import styles from './ListOfContent.module.css'
import { useCurrentBlogId } from 'data/blogs/client'
import Link from 'next/link'
import { BlogPostStatus } from 'data/pages/types'
// import { getStatusStylesForBlogPost } from 'data/pages/mapping'
import StatusPillForBlogPost from 'components/StatusPillForBlogPost'
import { CommentStatus } from 'data/comments/types'
import StatusPillForComment from 'components/StatusPillForComment'
import { getDateTinyFormat } from 'utilities/date'
// import { getStatusStylesForComment } from 'data/comments/mapping'
// CursorClickIcon, StarIcon,

// const getDateShortFormat = (dateIncoming: string | Date | undefined) => {
//   if (!dateIncoming) return ''
//   const date = (typeof dateIncoming === 'string')
//     ? new Date(dateIncoming)
//     : dateIncoming
//   const options = { year: 'numeric', month: 'long', day: 'numeric' } as Intl.DateTimeFormatOptions
//   return date.toLocaleDateString(undefined, options)
// }

const CONTENT_ITEMS_PER_PAGE = 100

// function classNames(...classes: string[]) {
//   return classes.filter(Boolean).join(' ')
// }

// const statusMap = {
//   shared: 'Tweeted',
//   not_shared: 'URL Not Tweeted',
//   no_conversation: 'Needs Conversation',
//   approved: 'Approved',
//   waiting: 'Awaiting Approval',
//   rejected: 'Rejected',
// }

// const mapStatus = (contentType: MediaType, status: BlogPostStatus | CommentStatus): string => {
//   if (contentType === MediaType.blogpost) {
//     const statusLabel = getLabelForBlogPostStatus(status as BlogPostStatus)
//     return statusLabel
//   }
//   if (contentType === MediaType.comment) {
//     const mappedStatus = statusMap?.[status]
//     return mappedStatus
//   }
//   return status
// }

const getStatusPill = (contentType: MediaType, status: BlogPostStatus | CommentStatus) => {
  if (contentType === MediaType.blogpost) {
    return <StatusPillForBlogPost status={status as BlogPostStatus} />
  }
  if (contentType === MediaType.comment) {
    return <StatusPillForComment status={status as CommentStatus} />
  }
  return <></>
}

// const getStatusStyle = (contentType: MediaType, status: BlogPostStatus | CommentStatus | undefined) => {
//   // console.log('GETSTATUSSTYLE', { contentType, status })
//   if (contentType === 'comment') {
//     return getStatusStylesForComment(status as CommentStatus)
//   }
//   if (contentType === 'blogpost') {
//     const style = getStatusStylesForBlogPost(status as BlogPostStatus)
//     console.log({ contentType, status, style })
//     return style
//   }
//   return ''
// }

export type ContentItem = {
  id: string,
  title: string,
  href: string,
  shareCount: number,
  pageViewCount: number,
  conversationCount?: number,
  likeCount: number,
  replyCount?: number,
  status?: BlogPostStatus | CommentStatus,
  date?: Date,
  datetime?: string,
}

const MediaType = {
  comment: 'comment',
  blogpost: 'blogpost',
  tweet: 'tweet'
}
type MediaType = (typeof MediaType)[keyof typeof MediaType]
type MediaTypeDataKeys =
  'title' |
  'labelName' |
  'pageViewCountName' |
  'conversationCountName' |
  'hasConversations' |
  'engagementsName' |
  'upvotesName' |
  'sharesName' |
  'repliesName' |
  'hasStatus' |
  'hasViews' |
  'urlPath'
type ContentTypeDictionary = {
  [key in MediaType]: {
    [key in MediaTypeDataKeys]: string | boolean
  }
}

const contentTypes: ContentTypeDictionary = {
  comment: {
    title: 'Comments',
    labelName: 'Text',
    pageViewCountName: 'Views + Impressions',
    engagementsName: 'Engagement',
    upvotesName: 'Likes',
    sharesName: 'Retweets',
    repliesName: 'Replies',
    conversationCountName: '',
    hasConversations: false,
    hasStatus: false,
    hasViews: false,
    urlPath: '/comments',
  },
  blogpost: {
    title: 'Blog posts',
    labelName: 'Title',
    pageViewCountName: 'Pageviews',
    engagementsName: 'Engagement',
    upvotesName: 'Likes',
    sharesName: 'Shares',
    repliesName: 'Comments',
    conversationCountName: 'Conversations',
    hasConversations: true,
    hasStatus: true,
    hasViews: true,
    urlPath: '/blogposts',
  },
  tweet: {
    title: 'Tweets',
    labelName: 'Tweet',
    pageViewCountName: 'Impressions',
    engagementsName: 'Engagement',
    upvotesName: 'Likes',
    sharesName: 'Retweets',
    repliesName: 'Replies',
    conversationCountName: '',
    hasConversations: false,
    hasStatus: false,
    hasViews: false,
    urlPath: '/tweets',
  }
}

type Props = {
  type: MediaType,
  content?: ContentItem[],
  maxItems?: number,
  metaData?: ContentMetadata,
}

type ContentMetadata = {
  blog?: Blog,
}

const formatTitle = (title: string, metadata?: ContentMetadata) => {
  // console.log({ metadata })
  if (metadata?.blog) {
    const blogDomain = `${metadata.blog.urlDomain}${metadata.blog.urlBasePath}`
    return title.replace(blogDomain, '').replace('https://', '') || blogDomain.replace('https://', '')

  }
  return title
}

const ListOfContent = ({ type, content: incomingContent, maxItems, metaData }: Props) => {
  const maxContentItems = maxItems || CONTENT_ITEMS_PER_PAGE
  const contentType = contentTypes[type]
  const content = incomingContent?.slice(0, maxContentItems)
  const blogSlug = useCurrentBlogId()
  // console.log('List of Contentn', { content })
  if (!content) {
    return <LoadingContent label='Loading...' />
  }
  return <div className={styles.listOfContent}>
    <div className={styles.header}>
      <h2 className="font-medium">
      { contentType.title }
      </h2>
      { maxItems && <p>
        <Link href={`/app/host/${blogSlug}${contentType.urlPath}`}>
          <a className='text-xs text-gray-300 sm:text-gray-500 underline underline-offset-1'>view all</a>
        </Link>
      </p> }
      <p className="text-xs text-gray-300 sm:hidden ml-auto">
        <span className="font-medium">1</span> to <span className="font-medium">{ Math.min(content.length, maxContentItems) }</span> of{' '}<span className="font-medium">{incomingContent?.length}</span>
      </p>
    </div>

    {/* Activity list (smallest breakpoint only) */}
    <div className="shadow sm:hidden">
      <ul role="list" className="mt-2 divide-y divide-gray-100 overflow-hidden shadow sm:hidden">
        {content
          .sort((a,b) => (a?.date || 0) > (b?.date || 0)
            ? -1
            : 1
          )
          .map((contentItem) => (
          <li key={contentItem.id}>
            <Link href={contentItem.href}>
              <a target={contentItem.href.startsWith('http') ? '_new' : undefined} className="block px-4 py-4 bg-white hover:bg-gray-50">
                <span className="flex items-center space-x-4">
                  <span className="flex-1 flex space-x-2 truncate">
                    <DocumentTextIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span className="flex flex-col text-gray-500 text-sm truncate">
                      <span className="truncate">{formatTitle(contentItem.title, metaData)}</span>
                      {/* <span>
                        <span className="text-gray-900 font-medium">{contentItem.shareCount}</span>{' '}
                      </span> */}
                      <div className={styles.engagementItemsRow}>
                        <span className="text-gray-900 flex">
                          <span className={styles.engagementItem}>
                            <ShareIcon className='w-4 h-4 mr-1 flex-shrink-0 text-cerise-400' />
                            {contentItem.shareCount}
                          </span>

                          {/* <span className={styles.engagementItem}>
                            <ThumbUpIcon className='w-4 h-4 mr-1 flex-shrink-0 text-green-500' />
                            {contentItem.likeCount}
                          </span> */}

                          { contentType.hasConversations && <span className={styles.engagementItem}>
                            <ChatAlt2Icon className='w-4 h-4 mr-1 flex-shrink-0 text-green-500' />
                            {contentItem.conversationCount}
                          </span> }

                          <span className={styles.engagementItem}>
                            <ChatIcon className='w-4 h-4 mr-1 flex-shrink-0 text-gray-500' />
                            {contentItem.replyCount}
                          </span>

                          { contentType.hasViews && <span className={styles.engagementItemLast}>
                            <EyeIcon className='w-4 h-4 mr-1 flex-shrink-0 text-blue-500' />
                            {contentItem.pageViewCount}
                          </span> }
                        </span>
                        <time className={styles.dateTimeMobile} dateTime={contentItem.date?.toLocaleString()}>{getDateTinyFormat(contentItem.date)}</time>
                      </div>
                    </span>
                  </span>
                  <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </a>
            </Link>
          </li>
        ))}
      </ul>

      {/* <nav
        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
        aria-label="Pagination"
      >
        <div className="flex-1 flex justify-between">
          <a
            href="#"
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
          >
            Previous
          </a>
          <a
            href="#"
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
          >
            Next
          </a>
        </div>
      </nav> */}
      </div>

      {/* Activity table (small breakpoint and up) */}
      <div className="hidden sm:block">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16">
        <div className="flex flex-col mt-2">
          <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-6 py-3 bg-white text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {contentType.labelName}
                  </th>
                  { contentType.hasStatus && <th className="hidden px-6 py-3 bg-white text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                    Status
                  </th> }
                  <th className="px-6 py-3 bg-white text-left text-xs font-medium text-gray-500 uppercase tracking-wider min-w-[200px]">
                    {contentType.engagementsName}
                  </th>
                  <th className="px-6 py-3 bg-white text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {content.map((contentItem) => {
                  // console.log({ contentItem })
                  return <tr key={contentItem.id} className="bg-white">
                    <td className={styles.columnTitle}>
                      <div className="flex">
                        <Link href={contentItem.href}>
                          <a className="group inline-flex space-x-2 truncate text-sm">
                            <DocumentTextIcon
                              className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            <p className="text-gray-500 truncate group-hover:text-gray-900" title={contentItem.title}>
                              {formatTitle(contentItem.title, metaData)}
                            </p>
                          </a>
                        </Link>
                      </div>
                    </td>
                    { contentType.hasStatus && <td className={styles.columnStatus}>
                      <Link href={contentItem.href}>
                        <a>
                          {!!contentItem.status && getStatusPill(type, contentItem.status)}
                        </a>
                      </Link>
                    </td> }
                    <td className={styles.columnEngagementItems}>
                      <span className="text-gray-900 flex">
                        <span className={styles.engagementItem} title='Shares'>
                          <ShareIcon className='w-4 h-4 mr-1 flex-shrink-0 text-cerise-400' />
                          {contentItem.shareCount}
                        </span>

                        {/* <span className={styles.engagementItem} title='Likes'>
                          <ThumbUpIcon className='w-4 h-4 mr-1 flex-shrink-0 text-green-500' />
                          {contentItem.likeCount}
                        </span> */}

                        { contentType.hasConversations && <span className={styles.engagementItem}>
                            <ChatAlt2Icon className='w-4 h-4 mr-1 flex-shrink-0 text-green-500' />
                            {contentItem.conversationCount}
                          </span> }

                        <span className={styles.engagementItem} title='Replies'>
                          <ChatIcon className='w-4 h-4 mr-1 flex-shrink-0 text-gray-500' />
                          {contentItem.replyCount}
                        </span>

                        { contentType.hasViews &&  <span className={styles.engagementItemLast} title='Views'>
                          <EyeIcon className='w-4 h-4 mr-1 flex-shrink-0 text-blue-500' />
                          {contentItem.pageViewCount}
                        </span> }
                      </span>
                    </td>
                    <td className={styles.columnDate}>
                      <time dateTime={contentItem.date?.toLocaleString()}>{getDateTinyFormat(contentItem.date)}</time>
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
            {/* Pagination */}
            <nav
              className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">1</span> to <span className="font-medium">{ Math.min(content.length, maxContentItems) }</span> of{' '}
                  <span className="font-medium">{incomingContent?.length}</span> results
                </p>
              </div>
              {/* <div className="flex-1 flex justify-between sm:justify-end">
                <a
                  href="#"
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Previous
                </a>
                <a
                  href="#"
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Next
                </a>
              </div> */}
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default ListOfContent
